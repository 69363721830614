import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import React, { Component } from 'react';
import { MdCloudDownload } from 'react-icons/md';

class Section8 extends Component {
    render() {
        return (
            <>
                <StaticQuery
                    query={graphql`
                        {
                            globalJson {
                                section8 {
                                    title
                                    textContent
                                    articles {
                                        articleTitle
                                        articleSubTitle
                                        articleDownloadPdfUrl
                                        image {
                                            childImageSharp {
                                                fluid(maxWidth: 1440) {
                                                    ...GatsbyImageSharpFluid_withWebp
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `}
                    render={(data) => (
                        <section className="splitGray md_sections section8">
                            <div className="row">
                                <div className="col-12 col-xl-4 textBlock">
                                    <h1>{data.globalJson.section8.title}</h1>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: data.globalJson.section8.textContent,
                                        }}
                                    />
                                </div>
                                <div className="col-12 col-xl-8">
                                    <div className="card-deck">
                                        <div className="card">
                                            <div className="col-1 iconCloud">
                                                <a
                                                    href={
                                                        data.globalJson.section8.articles[0]
                                                            .articleDownloadPdfUrl
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <MdCloudDownload />
                                                </a>
                                            </div>
                                            <Img
                                                className="card-img-top"
                                                fluid={
                                                    data.globalJson.section8.articles[0].image
                                                        .childImageSharp.fluid
                                                }
                                            />
                                            <div className="row card-body">
                                                <div className="col-11">
                                                    <a
                                                        href={
                                                            data.globalJson.section8.articles[0]
                                                                .articleDownloadPdfUrl
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <h5 className="card-title">
                                                            {
                                                                data.globalJson.section8.articles[0]
                                                                    .articleTitle
                                                            }
                                                        </h5>
                                                        <p className="card-text">
                                                            {
                                                                data.globalJson.section8.articles[0]
                                                                    .articleSubTitle
                                                            }
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="col-1 iconCloud">
                                                <a
                                                    href={
                                                        data.globalJson.section8.articles[1]
                                                            .articleDownloadPdfUrl
                                                    }
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <MdCloudDownload />
                                                </a>
                                            </div>
                                            <Img
                                                className="card-img-top"
                                                fluid={
                                                    data.globalJson.section8.articles[1].image
                                                        .childImageSharp.fluid
                                                }
                                            />
                                            <div className="row card-body">
                                                <div className="col-11">
                                                    <a
                                                        href={
                                                            data.globalJson.section8.articles[1]
                                                                .articleDownloadPdfUrl
                                                        }
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        <h5 className="card-title">
                                                            {
                                                                data.globalJson.section8.articles[1]
                                                                    .articleTitle
                                                            }
                                                        </h5>
                                                        <p className="card-text">
                                                            {
                                                                data.globalJson.section8.articles[1]
                                                                    .articleSubTitle
                                                            }
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    )}
                />
            </>
        );
    }
}

export default Section8;
