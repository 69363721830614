import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Section9View from './section9View';

const Section9 = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    section9 {
                        id
                        testimonialComment
                        testimonialTitle
                        testimonialSubTitle
                        image {
                            childImageSharp {
                                fluid(maxWidth: 340) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <>
                <section className="section9">{Section9View(data)}</section>
            </>
        )}
    />
);
export default Section9;
