import { graphql, StaticQuery, Link } from 'gatsby';
import React from 'react';
import { MdArrowForward } from 'react-icons/md';
import Section6View from './section6View';

const Section6 = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    section6 {
                        id
                        title
                        textContent
                        LearnMore
                        LearnMoreUrl
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <section className="row md_sections section6">
                <div className="col-12 textBlock">
                    <h1>Products for a better today and tomorrow</h1>
                    <p>Make future-focussed, data-driven decisions from one connected platform</p>
                </div>
                <>{Section6View(data)}</>
            </section>
        )}
    />
);
export default Section6;
