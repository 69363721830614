import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';

function Section7View(data) {
    console.log('section 7', data);
    const settings = {
        dots: false,
        arrows: false,
        fade: false,
        lazyLoad: true,
        autoplay: true,
        autoplaySpeed: 3500,
        infinite: true,
        swipeToSlide: true,
        slidesToShow: 6,
        speed: 1000,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const jsonSection7DataArray = [];

    data.globalJson.section7.map((item) =>
        jsonSection7DataArray.push(
            <div className="slideBlock" key={item.id}>
                <div className="card">
                    <Img
                        className="card-img-top testimonialImage"
                        fluid={item.image.childImageSharp.fluid}
                    />
                    <div className="card-body">
                        <h1 className="card-text"> {item.title} </h1>
                    </div>
                    <div className="card-btn">
                        <Link to={item.beReadyTextLink} className="btn btn_white">
                            {item.beReadyText}
                        </Link>
                    </div>
                </div>
            </div>
        )
    );
    return (
        <>
            <Slider className="md_sections" {...settings}>
                {jsonSection7DataArray}
            </Slider>
        </>
    );
}
export default Section7View;
