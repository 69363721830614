import React from 'react';
import Img from 'gatsby-image';
import { MdArrowForward } from 'react-icons/md';

function Section6View(data) {
    console.log('section 6', data);
    const jsonSection6DataArray = [];
    data.globalJson.section6.map((item) =>
        jsonSection6DataArray.push(
            <div key={item.id} className="card col-12 col-md-4">
                <Img className="card-img-top" fluid={item.image.childImageSharp.fluid} />
                <div className="card-body">
                    <h5 className="card-title">{item.title}</h5>
                    <p
                        className="card-text"
                        dangerouslySetInnerHTML={{ __html: item.textContent }}
                    />
                    <p className="card-text mt-5 mb-3">
                        <a
                            className="text-muted"
                            href={item.LearnMoreUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {item.LearnMore}
                            <span className="arrowOrange">
                                <MdArrowForward />
                            </span>
                        </a>
                    </p>
                </div>
            </div>
        )
    );
    return (
        <>
            <div className="row">{jsonSection6DataArray}</div>
        </>
    );
}
export default Section6View;
