import { graphql, StaticQuery } from 'gatsby';
import React from 'react';
import Section5View from './section5View';

const Section5 = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    section5 {
                        id
                        title
                        textContent
                        image {
                            childImageSharp {
                                fluid(maxWidth: 1000) {
                                    ...GatsbyImageSharpFluid_withWebp
                                }
                            }
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <>
                <section className="slide-wrapper section5">
                    <Section5View data={data} />
                </section>
            </>
        )}
    />
);
export default Section5;
