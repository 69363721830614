import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

const Section2 = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    section2 {
                        title
                        textContent
                    }
                }
            }
        `}
        render={(data) => (
            <>
                <section className="splitGray sm_sections">
                    <div className=" section section2">
                        <h1
                            className="h1"
                            dangerouslySetInnerHTML={{
                                __html: data.globalJson.section2.title,
                            }}
                        />
                        <p
                            dangerouslySetInnerHTML={{
                                __html: data.globalJson.section2.textContent,
                            }}
                        />
                    </div>
                </section>
            </>
        )}
    />
);
export default Section2;
