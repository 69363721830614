import Img from 'gatsby-image';
import React from 'react';
import Slider from 'react-slick';

class Section5View extends React.Component {
    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        const settings = {
            mobileFirst: true,
            dots: true,
            arrows: false,
            fade: false,
            lazyLoad: true,
            infinite: false,
            speed: 500,
            initialSlide: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            touchMove: true,
            adaptiveHeight: true,
            swipeToSlide: true,
            draggable: true,
            vertical: true,
            verticalSwiping: true,
            // touchThreshold: 30,
            prevArrow: 'prev',
            nextArrow: 'next',
            responsive: [
                {
                    breakpoint: 769,
                    settings: {
                        vertical: false,
                        verticalSwiping: false,
                        touchMove: true,
                        touchThreshold: 30,
                        adaptiveHeight: true,
                    },
                },
            ],
        };
        const jsonSection5DataArray = [];
        this.props.data.globalJson.section5.map((item) =>
            jsonSection5DataArray.push(
                <div className="slider-item" key={item.id}>
                    <div className="card">
                        <div className="row no-gutters">
                            <div className="col-12 col-md-5 align-self-top">
                                <Img
                                    className="card-img-top"
                                    fluid={item.image.childImageSharp.fluid}
                                />
                            </div>
                            <div className="col-12 col-md-7 align-self-top">
                                <div className="card-body">
                                    <h5 className="card-title">{item.title}</h5>
                                    <p
                                        className="card-text"
                                        dangerouslySetInnerHTML={{ __html: item.textContent }}
                                    />
                                    <p onClick={this.next}>Click to reveal.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        );

        return (
            <>
                <div className="lg_sections sliderNowNext textBlock">
                    <h1>
                        Bridge the gap between <b className="bold_title">now</b> and{' '}
                        <b className="bold_title">next</b>
                    </h1>
                    <Slider
                        ref={(slider) => (this.slider = slider)}
                        className="container"
                        id="#slider"
                        {...settings}
                    >
                        {jsonSection5DataArray}
                    </Slider>
                </div>
            </>
        );
    }
}
export default Section5View;
