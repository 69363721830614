import { graphql, Link, StaticQuery } from 'gatsby';
import React, { Component } from 'react';
import { MdArrowForward } from 'react-icons/md';
// import bgVideo from '../../images/onesource_hero_vid.mp4';
import { ScrollToLink } from '../../utils/scrollToLink';

class Section3 extends Component {
    render() {
        return (
            <>
                <StaticQuery
                    query={graphql`
                        {
                            globalJson {
                                section3 {
                                    title
                                    titleWork
                                    findMoreText
                                    findMoreTextLink
                                }
                            }
                        }
                    `}
                    render={(data) => (
                        <section className="section3">
                            {/* <p>{data.globalJson.section3.video}</p> */}
                            <div className="background-wrap">
                                {/* <video
                                    id="video-bg-elem"
                                    preload="auto"
                                    autoPlay
                                    loop
                                    muted="muted"
                                    width="100%"
                                    height="auto"
                                    src={bgVideo}
                                    type="video/mp4"
                                    controls="true"
                                /> */}
                                <iframe
                                    width="1280"
                                    height="720"
                                    src="https://www.youtube.com/embed/8JyAIGONhE4"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                />
                            </div>
                            <div className="row no-gutters textBlock">
                                <div className="col-12 col-md-8">
                                    <h5>{data.globalJson.section3.title}</h5>
                                    <p className="text_lightgray">
                                        {data.globalJson.section3.titleWork}
                                    </p>
                                </div>
                                <div className="col-12 col-md-4">
                                    <Link to={data.globalJson.section3.findMoreTextLink}>
                                        <button className="btn btn_whiteGray">
                                            {ScrollToLink(
                                                '/contact',
                                                data.globalJson.section3.findMoreText
                                            )}
                                            <MdArrowForward />
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </section>
                    )}
                />
            </>
        );
    }
}

export default Section3;
