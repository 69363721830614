import { graphql, StaticQuery, Link } from 'gatsby';
import React from 'react';
import { MdArrowForward } from 'react-icons/md';

const Section10 = () => (
    <StaticQuery
        query={graphql`
            {
                globalJson {
                    section10 {
                        title
                        textContent
                        btnText
                        btnLink
                    }
                }
            }
        `}
        render={(data) => (
            <>
                <section className="splitGray sm_sections section10">
                    <div className="section textBlock">
                        <div className="row no-gutters">
                            <div className="col-12 col-lg-8">
                                <h1>{data.globalJson.section10.title}</h1>
                                <p>{data.globalJson.section10.textContent}</p>
                            </div>
                            <div className="col-12 col-lg-4 align-self-center">
                                <Link to={data.globalJson.section10.btnLink}>
                                    <button className="btn btn_orange float-lg-right">
                                        {data.globalJson.section10.btnText}
                                    </button>
                                </Link>
                            </div>
                            {/* <div id="home_newsletter" className="col-12 col-md-4">
                                <div className="input-group">
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter your Email address"
                                        aria-label="Newsletter"
                                        aria-describedby="basic-addon2"
                                    />
                                    <div className="input-group-append">
                                        <button className="btn btn-outline-secondary" type="button">
                                            <MdArrowForward />
                                        </button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </>
        )}
    />
);
export default Section10;
