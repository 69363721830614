import { graphql, StaticQuery } from 'gatsby';
import React, { Component } from 'react';
//import bgVideo from '../../images/ONESOURCE-HERO.mp4';
import DownArrow from '../../images/down_arrow.svg';

class Section1 extends Component {
    render() {
        return (
            <>
                <StaticQuery
                    query={graphql`
                        {
                            globalJson {
                                section1 {
                                    title
                                }
                            }
                        }
                    `}
                    render={(data) => (
                        <section className="section1">
                            {/* <p>{data.globalJson.section1.video}</p> */}
                            <div className="background-wrap">
                                <video
                                    id="video-bg-elem"
                                    src="https://tomson-reuters.s3.eu-west-2.amazonaws.com/ONESOURCE-HERO.mp4"
                                    preload="auto"
                                    autoPlay
                                    loop
                                    muted="muted"
                                    width="100%"
                                    height="400"
                                    type="video/mp4"
                                />
                                <div className='down-arrow d-none d-lg-flex'>
                                   <img  src={DownArrow} />
                                </div>
                            </div>
                        </section>
                    )}
                />
            </>
        );
    }
}

export default Section1;
