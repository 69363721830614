import { graphql, Link, StaticQuery } from 'gatsby';
import React, { Component } from 'react';
import { MdCloudDownload } from 'react-icons/md';
import { ScrollToLink } from '../../utils/scrollToLink';

class Section4 extends Component {
    render() {
        return (
            <>
                <StaticQuery
                    query={graphql`
                        {
                            globalJson {
                                section4 {
                                    title
                                    textContent
                                    linkText
                                    linkUrl
                                    btnText
                                    btnTextUrl
                                }
                            }
                        }
                    `}
                    render={(data) => (
                        <section className="splitGray sm_sections section4">
                            <div className="row no-gutters">
                                <div className="col-12 col-xl-8">
                                    <div className="section textBlock">
                                        <h1>{data.globalJson.section4.title}</h1>
                                        <p>{data.globalJson.section4.textContent}</p>
                                        <p className="linkText">
                                            <a
                                                href={data.globalJson.section4.linkUrl}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {data.globalJson.section4.linkText}
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-4">
                                    <a
                                        href={data.globalJson.section4.btnTextUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <button className="btn btn_orange ">
                                            {data.globalJson.section4.btnText}
                                            <MdCloudDownload
                                                className="ml-3"
                                                style={{ fontSize: '24px' }}
                                            />
                                        </button>
                                    </a>
                                </div>
                            </div>
                        </section>
                    )}
                />
            </>
        );
    }
}

export default Section4;
