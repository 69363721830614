import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Section7View from './section7View';

class Section7 extends Component {
    render() {
        return (
            <>
                <StaticQuery
                    query={graphql`
                        {
                            globalJson {
                                section7 {
                                    title
                                    beReadyText
                                    beReadyTextLink
                                    image {
                                        childImageSharp {
                                            fluid(maxWidth: 2000) {
                                                ...GatsbyImageSharpFluid_withWebp
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    `}
                    render={(data) => (
                        <>
                            <section className="sm_sections section7">{Section7View(data)}</section>
                        </>
                    )}
                />
            </>
        );
    }
}

export default Section7;
