import React from 'react';
import Img from 'gatsby-image';
import Slider from 'react-slick';

function Section9View(data) {
    console.log('section 9', data);
    const settings = {
        dots: true,
        arrows: false,
        fade: false,
        lazyLoad: true,
        infinite: true,
        swipeToSlide: true,
        slidesToShow: 6,
        speed: 500,
        initialSlide: 0,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const jsonSection9DataArray = [];

    data.globalJson.section9.map((item) =>
        jsonSection9DataArray.push(
            <div className="slideBlock" key={item.id}>
                <blockquote className="testimonialComment quoteStyle">
                    {item.testimonialComment}
                </blockquote>
                <div className="row no-gutters testimonialContent">
                    <div className="testimonialImageBlock align-self-center">
                        <Img
                            className="ml-0 ml-md-5 testimonialImage"
                            fluid={item.image.childImageSharp.fluid}
                        />
                    </div>
                    <div className="ml-3 testimonialPerson">
                        <h2 className="testimonialTitle">{item.testimonialTitle}</h2>
                        <p className="testimonialSubTitle">{item.testimonialSubTitle}</p>
                    </div>
                </div>
            </div>
        )
    );
    return (
        <>
            <Slider className="md_sections sliderComments" {...settings}>
                {jsonSection9DataArray}
            </Slider>
        </>
    );
}
export default Section9View;
