import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Section1 from '../components/sections/section1';
import Section2 from '../components/sections/section2';
import Section3 from '../components/sections/section3';
import Section4 from '../components/sections/section4';
import Section5 from '../components/sections/section5';
import Section6 from '../components/sections/section6';
import Section7 from '../components/sections/section7';
import Section8 from '../components/sections/section8';
import Section9 from '../components/sections/section9';
import Section10 from '../components/sections/section10';

const IndexPage = () => (
    <Layout>
        <SEO title="Home" />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Section7 />
        <Section8 />
        <Section9 />
        <Section10 />
    </Layout>
);

export default IndexPage;
